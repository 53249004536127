<template>
  <v-card elevation="0" class="chart-card">
    <v-card-title class="title-card-chart"
      >Gasto mensal por campanha em R$ - {{ query.mes_ref }}/{{
        query.ano_ref
      }}</v-card-title
    >
    <v-card-text>
      <Chart :chartOptions="chartOptions" />
    </v-card-text>
  </v-card>
</template>

<script>
import campanha from "@/services/http/campanhaService";

export default {
  props: {
    categories: {},
    series: {},
    query: {}
  },
  components: {
    // Chart
    Chart: () => import("@/components/shared/BaseChartTeste")
  },
  data() {
    return {
      colors: [
        "#D46D26",
        "#951CD4",
        "#11C9D4",
        "#D42F1C",
        "#31D481",
        "#E09E1D",
        "#5DD9BD",
        "#B3D43B",
        "#1162D4",
        "#E07228",
        "#0DA156",
        "#873D0C",
        "#FF9147",
        "#268756",
        "#D49C31"
      ],
      enterprise: 0,
      years: {},
      dataDashboard: { name: "Resultado", data: [] },
      totalBonus: 0,
      chartOptions: {
        chart: {
          type: "column",
          // type: "areaspline",
          backgroundColor: "rgba(0,0,0,0)",
          height: 300
        },
        // linear-gradient(90deg, rgba(10,32,58,1) 0%, rgba(42,87,135,1) 100%)
        title: {
          text: ""
        },
        legend: {
          itemStyle: {
            color: "rgba(255,255,255, 0.3)"
          }
        },
        xAxis: {
          categories: ["Campanha"],
          labels: {
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          }
        },
        yAxis: {
          title: {
            text: "Valor Bônus",
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          },
          gridLineColor: "rgba(255,255,255, 0.1)",
          labels: {
            style: {
              color: "rgba(255,255,255, 0.3)"
            }
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
        },
        credits: {
          enabled: false
        },
        series: [],
        lang: {
          noData: "Não há dados para serem apresentados"
        }
      }
    };
  },
  methods: {
    async fetchResultadoRealizadoCampanha() {
      this.chartOptions.series = [];
      let {
        data: { data }
      } = await campanha()
        .resultadoRealizadoCampanha()
        .show({
          per_page: -1,
          ...this.query
        });
      const obj = this.groupByCompany(data, "id_resultado_realizado_campanha");
      this.chartOptions.series = Object.values(obj);
    },

    groupByCompany(array, key) {
      return array.reduce((result, currentValue, index) => {
        (result[currentValue[key]] = result[currentValue[key]] || {
          name: `${currentValue.apelido} - ${currentValue.id_campanha}`,
          campanha: currentValue.nome_campanha,
          empresa: currentValue.apelido,
          data: [0],
          color: this.colors[index]
        }).data[0] = result[currentValue[key]].data[0] += parseInt(
          currentValue.resultado
        );
        return result;
      }, {});
    }
  },
  watch: {
    query() {
      this.fetchResultadoRealizadoCampanha();
    }
  },
  async created() {
    await this.fetchResultadoRealizadoCampanha();
  }
};
</script>

<style>
.chart-card {
  border-radius: 0px !important;
  background: #141e30;
  background: linear-gradient(90deg, #0c2646 0, #204065 60%, #2a5788);
  padding-bottom: 40px;
}

.title-card-chart {
  color: white;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}
</style>
